import Logger from "js-logger";
import {
  nextContentLoadImage,
  setNextContentData,
} from "../actions/nextContent";
import { getImageProxyUrl } from "./util";

import pkg from "../../package.json";
import { config } from "../conf";

const logger = Logger.get("NextContent");

export class NextContent {
  private dispatch: any;
  private getState: any;
  private changeAsset: any;

  constructor(dispatch: any, getState: any) {
    this.dispatch = dispatch;
    this.getState = getState;
  }

  public async fetchNextContent(
    changeAsset: (asset: any) => void,
  ): Promise<void> {
    this.changeAsset = changeAsset;
    const { content, user } = this.getState();
    const query = {
      query: `
      query Endscreen($assetId: ID!) {
        endScreen(id: $assetId) {
          nextContent {
            ... on Episode {
              id
              title
              images {
                main16x9 {
                  sourceEncoded
                }
              }
            }
          }
        }
      }`,
      variables: {
        assetId: content.asset.metadata.id,
      },
    };

    try {
      const { graphqlEndpoint } = config;
      const response = await fetch(graphqlEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Client-Name": "avod-chromecast",
          "Client-Version": pkg.version,
          ...(user.authorizationToken && {
            Authorization: `Bearer ${user.authorizationToken}`,
          }),
        },
        body: JSON.stringify(query),
      });
      const responseData = await response.json();

      const nextContent = responseData?.data?.endScreen?.nextContent;

      // if there is no next content, then nextContent can be either `{}` or null
      if (!nextContent?.id) {
        this.dispatch(setNextContentData(null));
        return;
      }

      this.dispatch(setNextContentData(nextContent));

      const imageUrl = nextContent?.images?.main16x9?.sourceEncoded;

      if (imageUrl) {
        this.dispatch(nextContentLoadImage(getImageProxyUrl(imageUrl, 600)));
      }
    } catch (error) {
      logger.error(error);
    }
  }

  public start() {
    const assetId = this.getState()?.nextContent?.data?.id;
    if (assetId && this.changeAsset) {
      // this.dispatch(actions.broadcastMessageToSenders(nextAsset, 0))
      this.changeAsset(assetId);
      return true;
    }
    return false;
  }
}
